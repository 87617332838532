import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  // {
  //   id: 1,
  //   label: "MENUITEMS.MENU.TEXT",
  //   isTitle: true,
  // },
  // {
  //   id: 2,
  //   label: "MENUITEMS.DASHBOARDS.TEXT",
  //   icon: "bx-home-circle",
  //   subItems: [
  //     {
  //       id: 3,
  //       label: "MENUITEMS.DASHBOARDS.LIST.DEFAULT",
  //       link: "/",
  //       parentId: 2,
  //     },
  //     {
  //       id: 4,
  //       label: "MENUITEMS.DASHBOARDS.LIST.X",
  //       link: "/x",
  //       parentId: 2,
  //     },
  //   ],
  // },

  {
    id: 1,
    label: "Patients",
    icon: "bxs-user-detail",
    link: "/patients",
    // subItems: [
    //   {
    //     id: 6,
    //     label: "Patients",
    //     link: "/patients",
    //     parentId: 5,
    //   },
    //   {
    //     id: 7,
    //     label: "Add Patient",
    //     link: "/addPatient",
    //     parentId: 5,
    //   },
    //   {
    //     id: 8,
    //     label: "Edit Patient",
    //     link: "/editPatient",
    //     parentId: 5,
    //   },
    // ],
  },
  {
    id: 2,
    label: "Appointments",
    icon: "bx bx-calendar",
    link: "/appointments/view-appointments",
  },
  // {
  //   id: 9,
  //   label: "MENUITEMS.DATA_MANAGEMENT.TEXT",
  //   icon: "bx-home-circle",
  //   subItems: [
  //     {
  //       id: 10,
  //       label: "MENUITEMS.DATA_MANAGEMENT.LIST.Patients",
  //       link: "/patients",
  //       parentId: 9,
  //     },

  //   ],
  // },

 


];
