import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    httpOption = {
        headers: new HttpHeaders()
          .set('content-type', 'application/json')
        //   .set('Access-Control-Allow-Origin', '*')
        //   .set('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT')
        //   .set('Authorization', 'Bearer ' + this.token),
      }

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private currentUserRoleSubject: BehaviorSubject<any>;
    constructor( private toastr: ToastrService,private http: HttpClient,private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentUserRoleSubject=new BehaviorSubject<any>(JSON.parse(localStorage.getItem('role')));
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentUserRoleValue(): any {
        return this.currentUserRoleSubject.value;
    }
    /**
     * Returns the current user
     */
    // public currentUsers(): User {
    //     return getFirebaseBackend().getAuthenticatedUser();
    // }

    /**
     * Performs the auth
     * @param mobileNumber mobileNumber of user
     * @param password password of user
     */
    // login(email: string, password: string) {
    //     return getFirebaseBackend().loginUser(email, password).then((response: any) => {
    //         const user = response;
    //         return user;
    //     });
    // }

    login(data:any):Observable<any>{       
        return this.http.post<any>(`${environment.baseUrl}/user/login`, data,this.httpOption)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user?.payLoad?.authToken) {
                   
                    if(user.payLoad?.role=='ROLE_DOCTOR')
                    {
                       
                        // this.user.firstName=user.payLoad?.firstName;
                        const newuser:User={
                            id:user.payLoad?.userId,
                            firstName:user.payLoad?.firstName,
                            lastName:user.payLoad?.lastName,
                            email:user.payLoad?.email,
                            token:user.payLoad?.authToken,
                        }
                        
                        localStorage.setItem('currentUser', JSON.stringify(newuser));
                        localStorage.setItem('role', JSON.stringify(user.payLoad?.role));
                        localStorage.setItem('dpLink', JSON.stringify(user.payLoad?.dpLink));
                        localStorage.setItem('userName', JSON.stringify(user.payLoad?.firstName));
                        this.currentUserSubject.next(newuser);
                        this.currentUserRoleSubject.next(user.payLoad?.role);
                        this.toastr.success("Login Success", '',{ timeOut: 2000 });
                    }else{
                        this.toastr.error("Unauthorized User", '',{ timeOut: 2000 });
                        throw Error("Unauthorized User");
                    }
                    
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                  
                }
            })); 
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }

    reset(){
        this.currentUserSubject.next(null);
    }
}

