import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ChatMsg } from "../Interface/Chat";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private chatBadgeSubject: BehaviorSubject<boolean>;
  constructor(private http: HttpClient) {
    this.chatBadgeSubject = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('chatCount')));
  }
  public get showChatBadge(): boolean {
    return this.chatBadgeSubject.value;
}

public setChatBadge(value){
  this.chatBadgeSubject.next(value)
}
  chat(groupid:number){
    return this.http.get(`${environment.baseUrl}/chat/${groupid}`);
  }
  fileUpload(formdata:any){
    return this.http.post(`${environment.baseUrl}/chat/upload`, formdata);
  }

  sendmsg(body:ChatMsg){
    return this.http.post(`${environment.baseUrl}/chat/message/send`, body);
  }
  readMsg(msgid:number){
    let body=null
    return this.http.post(`${environment.baseUrl}/chat/message/${msgid}/read`,body);
  }

  readAllMsg(group_id:number){
    let body=null
    return this.http.post(`${environment.baseUrl}/chat/group/${group_id}/readall`,body);
  }

  startchat(userid:number){
    let body=null
    return this.http.post(`${environment.baseUrl}/chat/start/${userid}`,body);
  }
  
  getGroups(){
    return this.http.get(`${environment.baseUrl}/chat/groups/active`);
  }
}
