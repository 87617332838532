import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface Post {
  postType: string;
  postStatus: string;
  title: string;
}

export interface PostDetails {
  blogImageUrl: string;
  content: string;
  post: Post;
  questionAttachmentType: string;
  questionAttachmentUrl: string;
  questionTitle: string;
}

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss']
})
export class PostViewComponent implements OnInit {

  postDetails = {} as PostDetails;
  post = {} as Post;
  postId: number;


  title: string;
  content: string;
  imageUrl: string;
  imageType: string; //Image, Video

  constructor(
    private http: HttpClient,
    private routerAct: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.postId = this.routerAct.snapshot.params['id'];
    this.getPostDetails();
  }

  getPostDetails(){
    this.http.get(`${environment.baseUrl}/forums/`+ this.postId +`/get_post`)
            .pipe(map(res => res)).subscribe(
              res => {
                console.log(res['payLoad'][0]);
                this.postDetails = res['payLoad'][0];
                this.post = this.postDetails.post;

                if(this.post.postType == 'BLOG'){
                  this.title = this.post.title;
                  this.content = this.postDetails.content;
                  this.imageUrl = this.postDetails.blogImageUrl;
                  this.imageType = 'IMAGE'
                } else if(this.post.postType == 'QUESTION'){
                  this.title = this.postDetails.content;
                  this.imageUrl = this.postDetails.questionAttachmentUrl;
                  this.imageType = this.postDetails.questionAttachmentType;
                } else if(this.post.postType == 'QUESTION_OF_THE_WEEK'){
                  this.title = this.postDetails.content;
                  this.imageUrl = this.postDetails.questionAttachmentUrl;
                  this.imageType = this.postDetails.questionAttachmentType;
                }
              }
            );
  }

  appDownload(){
    console.log("appDownload");
    
    if(navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.open('https://play.google.com/store/apps/details?id=com.oncobuddy.app', '_blank');
      // window.location.href= "https://play.google.com/store/apps/details?id=com.oncobuddy.app";
    }
  }

}
