<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
* {
  box-sizing: border-box;
}

/* Add a gray background color with some padding */
body {
  /* font-family: Arial; */
  padding: 20px;
  background: #f1f1f1;
}

/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {
  float: left;
  width: 75%;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.card {
   background-color: white;
   padding: 20px;
   margin-top: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .leftcolumn, .rightcolumn {
    width: 100%;
    padding: 0;
  }
}
</style>
</head>
<body>
  <div class="d-flex border bg-white box-shadow rounded justify-content-between p-2 align-items-center">
    <div class="left_section d-flex align-items-center  p-2 ">
      <div class="icon">
        <img src="./../../../assets/images/Onco Buddy.png"  class="rounded">
      </div>
      <div class="right_content ml-2">
        <h4 class="mb-0"> Oncobuddy..!</h4>
        <p class="mb-0">For a better experience,  download! </p>
      </div>
    </div>
    <div class="playStore-icon">
      <button type="btn" (click)="appDownload()">
        <img src="./../../../assets/images/playstore.png">
        <p class="first mb-0">Get it On</p>  <h5 class="mb-0">Google Play</h5>
      </button>
    </div>
  </div>


<div class="row">
  <div class="leftcolumn">
    <div class="card">
      <h2>{{title}}</h2>
      <!-- <h5>Title description, Dec 7, 2017</h5> -->

        <div *ngIf="imageUrl != null && imageUrl.length > 0 && imageType == 'IMAGE'">
          <img src="{{imageUrl}}" width="200" height="200">
        </div>

        <div *ngIf="imageType == 'VIDEO'">
          <video width="350" controls>
            <source src="{{imageUrl}}" type="video/mp4">
            Your browser does not support HTML video.
          </video>
        </div>

      <div class="panel-body" [innerHtml]='content'></div>
    </div>
  </div>
</div>

</body>
</html>
