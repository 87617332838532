import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LayoutService {
    private currentLayoutSubject: BehaviorSubject<boolean>;
    public currentLayout: Observable<boolean>;
    constructor(){
        this.currentLayoutSubject=new BehaviorSubject<boolean>(false)
        this.currentLayoutSubject.asObservable();
    }

    public get currentLayoutValue(): any {
        return this.currentLayoutSubject.value;
    }

    public setLayoutHidden(value:boolean) {
         this.currentLayoutSubject.next(value);
    }
    
}