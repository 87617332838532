import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    role:any
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authFackservice: AuthfakeauthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (environment.defaultauth === 'firebase') {
          
            // const currentUser = this.authenticationService.currentUser();
            // if (currentUser) {
            //     // logged in so return true
            //     return true;
            // }
        } else {
            this.role=this.authenticationService.currentUserRoleValue;
            const currentUser = this.authenticationService.currentUserValue;
            if (currentUser && this.role=='ROLE_DOCTOR') {
                // logged in so return true
                return true;
            }
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'] );
        // { queryParams: { returnUrl: state.url } }
        return false;
    }
}
